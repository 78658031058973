<template>
    <a-layout>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <mymenu />
        </a-layout-sider>
        <a-layout>
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                <!-- 内容填充区 -->
                <a-input 
                    v-model:value="money"
                    placeholder="请输入特定的json数据"
                    style="
                        width: 72%;
                        margin-right: 8px" 
                    @@keyup.enter.native="send_wb"
                />
                <a-button type="primary" html-type="submit" @click="send_wb">支付</a-button>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
export default {
    components: {
        mymenu: menu
    },
    data() {
        return {
            key: "value",
            money: '',
        }
    },
    methods: {
        send_wb(){
            console.log("123123");
            this.myaxios("pay/alipay/","get", {"method": "pay", "total_amount": this.money, "subject": "充值"}).then((res) => {
                console.log(res);
                
            }).catch((err) => {
                console.log(err);
            });
        },
    },
    mounted() {
    },
    created() { }
}
</script>

<style scoped>
@import url('../assets/style.css');
</style>